.email-builder-loading-box {
    border: 1px solid #dee2e6;
    background: #ffffff;
    border-radius: 0.375rem;
    padding-top: 10px;
}

.report-alert {
    margin: 0 0 1rem 0;
}

.loading-bar {
    height: 30px;
    font-weight: bold;
}

.fetch-spinner {
    margin-right: 8px;
}

.progress-messages-header .accordion-button {
    background-color: rgba(33, 40, 50, 0.03);
    border-bottom: 1px solid rgba(33, 40, 50, 0.125);
    box-shadow: none;
}

.progress-messages {
    box-shadow: 0 0.15rem 1.75rem 0 rgb(33 40 50 / 15%);
}

a.input-link {
    margin-right: 10px;
    color: black;
}

a.input-link-disabled {
    pointer-events: none;
    color: lightgrey;
}

.remove-company-btn {
    width: 32px;
    height: 32px;
    padding: 0;
}

.email-input-row {
  transition: all 0.3s ease;
}

.email-input-fields {
  display: flex;
  flex-grow: 1;
}

.email-input-fields > *:not(:last-child) {
  margin-right: 1rem;  /* equivalent to gap-3 in Bootstrap */
}

.remove-email-btn {
  height: 32px;
  width: 32px;
}

.lh-16 {
  line-height: 1.6;
}