.search-box {
  display: inline-block;
  margin-left: 20px;
  margin-right: 0;
  max-width: 50%;
  border-radius: 2px;
  padding: 4px 55px 4px 15px;
  position: relative;
  background: #fff;
  border: 1px solid #ddd;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

@media (max-width: 660px) {
    .search-box {
        display: none;
    }
}

.search-box.hovered, .search-box:hover, .search-box:active {
  border: 1px solid lightgrey;
}

.search-box input[type=text] {
  font-size: 0.875rem !important;
  border: none !important;
  box-shadow: none !important;
  display: inline-block;
  padding: 0;
  background: transparent;
  border-radius: 0;
}

.search-box input[type=text]:hover, .search-box input[type=text]:focus, .search-box input[type=text]:active {
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
}

.search-box .search-btn {
  position: absolute;
  padding: 0 5px;
  color: #aaa !important;
  border-radius: 3px;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

.search-box .search-btn-cross {
  right: 30px;
}

.search-box .search-btn-search {
  right: 5px;
}

.search-box .search-btn:hover {
  color: var(--bs-body-color) !important;
}

.search-box-results {
  position: absolute;
  top: 100%; /* Positions the top of the div right below the search form */
  left: 0;
  width: 130%; /* Adjust width as necessary */
  z-index: 1; /* Ensures it does not overlap items you don't want it to overlap */
  margin-top: 4px; /* Space between the search form and the results div */
  background-color: white; /* Optional: for visibility against other content */
  box-shadow: 0 2px 2px rgba(0,0,0,0.2); /* Optional: adds shadow for better visibility */
  border-radius: 3px;
  border: 1px solid #dce1e5;
  overflow: hidden;
}

.search-box-results .results-divider {
  border-top: 1px solid #dce1e5;
}

.btn-search-result {
  padding: 0;
  border: 0;
  outline: none;
  border-radius: 0;
}

.btn-search-result .list-group-item:hover {
  background: hsl(240 4.8% 95.9%);
}

.search-list .focused {
  background: hsl(240 4.8% 95.9%) !important;
}

.btn-search-result .list-group-item {
  border: none;
}

.search-box-results .deal-logo {
  border: 1px solid #ccc;
  border-radius: 4px;
}