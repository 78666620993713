#grid-container {
   display:grid;
   grid-template-rows:auto 1fr auto;
   grid-template-columns:100%;

   /* fallback height */
   min-height:100vh;

   /* new small viewport height for modern browsers */
   min-height:100svh;
}

#page-title {
   margin-bottom: 20px;
   font-size: 1.6rem;
}

.hr-title {
   border-top: 2px solid rgba(33, 40, 50, 0.25);
}

.column-container {
   padding: 0;
   min-height: 100%;
   box-shadow: 0 0.5em 1.5em rgba(0, 0, 0, .1), 0 0.125em 0.5em rgba(0, 0, 0, .15);
   display: flex;
   flex-wrap: nowrap;
}
