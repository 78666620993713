.btn-template {
    width: auto;
    display: inline-block;
    margin-right: 10px;
}

.h6-label {
    line-height: inherit;
    margin-bottom: 0;
}

.email-table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid rgba(33, 40, 50, 0.125);
}

.email-table th {
    font-weight: 600;
    background-color: #f1f1f1;
}