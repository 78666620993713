/* Customizes the scrollbar track within .mdxeditor-toolbar */
.mdxeditor-toolbar::-webkit-scrollbar {
  width: 5px; /* For vertical scrollbar */
  height: 5px; /* For horizontal scrollbar */
}

.mdxeditor-toolbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Customizes the scrollbar handle within .mdxeditor-toolbar */
.mdxeditor-toolbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}

.mdxeditor-toolbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.mdxeditor-diff-source-wrapper {
    padding-left: 10px;
    padding-right: 10px;
}

.mdxeditor-toolbar {
    border-radius: 0;
    background-color: rgba(248, 248, 249, 1);
    border-bottom: 1px solid rgba(33, 40, 50, 0.125);
    height: 52px;
}

.mdxeditor-root-contenteditable ul {
    line-height: 1.6;
}