/* OVERALL SETTINGS */

.deal-main-content {
    min-width: 0;
}

.deal-main-content hr, .deal-sidebar hr {
    color: #dee2e6;
    opacity: 1;
}

/* SIDEBAR SETTINGS */

.deal-page-nav svg {
    width: 15px;
}

.deal-page-nav .nav-label {
    margin-left: 10px;
}

.deal-sidebar {
    border-right: 1px solid rgba(0, 0, 0, 0.25);
    position: sticky;
    top: 0;
    height: calc(100vh - 62px);
    overflow-y: auto;
    width: 280px;
}

@media (max-width: 767px) {
    .deal-sidebar {
        width: 75px;
    }
}

.deal-sidebar::-webkit-scrollbar {
  width: 5px; /* For vertical scrollbar */
  height: 5px; /* For horizontal scrollbar */
}

.deal-sidebar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.deal-sidebar::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 6px;
}

.deal-sidebar::-webkit-scrollbar-thumb:hover {
  background: #888;
}

/* REPORT / EMAIL TABLE SETTINGS */

.deal-table .table-responsive {
    border: 1px solid #dee2e6;
    border-bottom: 0;
}

.deal-table .pagination-container {
    background-color: #f1f1f1;
    border: 1px solid #dee2e6;
    border-top: 0;
    padding: 3px 0;
}

/* EMAILS BUTTONS HEADER */

.deal-emails-header label {
    font-size: 0.875rem;
}