/* ABOUT SECTION SETTINGS */

.company-about-logo {
    border: 1px solid #ccc;
    border-radius: 6px;
}

.deal-about-table .css-table-row div {
    padding-bottom: 5px;
}

.deal-about-table .css-table-row div:first-child {
    padding-right: 30px;
}

/* SOCIAL ICONS SETTINGS */

.deal-socials {
    padding: 0 6px;
}

.deal-socials .social-icon {
    width: 20px;
    vertical-align: baseline;
}

.deal-socials a {
    display: inline-block;
    width: 20px;
    height: 20px;
    overflow: hidden;
    position: relative;
    border-radius: 20%;
    margin-right: 5px;
}

.deal-socials a::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top right, rgba(255,255,255,0.2), rgba(0,0,0,0.2));
    border-radius: 20%; /* Matches the anchor border-radius */
    pointer-events: none; /* Allows clicks to pass through to the anchor */
}

.deal-socials a:hover::after {
    background: linear-gradient(to bottom left, rgba(255,255,255,0.3), rgba(0,0,0,0.3));
}