.news-item {
    border-left: 5px solid #e5e5e5;
}

.news-item .news-label {
    padding: 0 3px;
    border: 1px solid #e2e2e2;
    position: relative;
    background-image: linear-gradient(#fff, #f2f2f2);
    display: inline-block;
    border-radius: 2px;
}
