ul.email-list {
    margin-bottom: 0;
}

.email-list li {
    align-items: center;
    padding-left: 10px;
    margin-bottom: 6px;
}

.email-list svg {
    width: 15px;
}

.email-list li:last-child {
    margin-bottom: 0;
}

.email-sidebar {
    border-right: 1px solid rgba(33, 40, 50, 0.125);
}

.email-sidebar-header {
    background-color: rgba(248, 248, 249, 1);
    color: var(--slate-11);
    padding: 0 1.35rem 0;
    font-weight: bold;
    margin-bottom: 12px;
    border-bottom: 1px solid rgba(33, 40, 50, 0.125);
    height: 52px;
    display: flex;
    align-items: center; /* Aligns vertically center */
}

.email-sidebar-list button {
    margin-bottom: 10px;
}

.email-sidebar-list button:last-child {
    margin-bottom: 0;
}

.email-icon {
    margin-right: 10px;
}

button.btn-email-sidebar {
    padding: 0;
    border: 0;
    border-radius: 4px;
}

.email-sidebar-list .list-group-item {
    display: flex;
    align-items: center;
}

.email-sidebar-list .list-group-item:hover {
    background: hsl(240 4.8% 95.9%);
}

.email-sidebar-list .list-group-item.email-sidebar-current {
    background: hsl(240 4.8% 95.9%);
}

button.btn-email-control {
    background-color: transparent;
    border: none; /* Optional, remove if you don't want borders */
    border-radius: 4px;
    min-width: 34px;
}

button.btn-email-control:hover {
    background-color: rgba(0.88, 0.88, 0.9, 0.1);
    color: black; /* Ensure the text color remains black on hover */
}

.marked-sent-text {
    font-weight: normal;
    font-size: small;
    margin-right: 20px;
}

.email-template-accordion {
    padding: 0 12px;
}

.email-template-accordion .accordion-button {
    background: none;
}

.email-template-accordion .accordion-button:focus {
    box-shadow: none;
}

.email-template-accordion .accordion-button:not(.collapsed) {
    background-color: hsl(240 4.8% 95.9%);
    color: inherit;
    border-bottom: 1px solid rgba(33, 40, 50, 0.125);
}

.email-template-accordion .collapsing {
    border-top: none;
}

span.template-dynamic-text {
    color: red;
}
.email-template-accordion .social-icon {
    height: 20px;
    margin-right: 8px;
}