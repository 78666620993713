.report-builder-company-profile {
    border: 1px solid #dee2e6;
    background: #ffffff;
    border-radius: 0.375rem;
    padding-top: 10px;
}

.report-builder-company-profile .company-about-card {
    margin: 10px 20px;
}

.report-alert {
    margin: 0 0 1rem 0;
}

.loading-bar {
    height: 30px;
    font-weight: bold;
}

.fetch-spinner {
    margin-right: 8px;
}

.progress-messages-header .accordion-button {
    background-color: rgba(33, 40, 50, 0.03);
    border-bottom: 1px solid rgba(33, 40, 50, 0.125);
    box-shadow: none;
}

.progress-messages {
    box-shadow: 0 0.15rem 1.75rem 0 rgb(33 40 50 / 15%);
}

a.input-link {
    margin-right: 10px;
    color: black;
}

a.input-link-disabled {
    pointer-events: none;
    color: lightgrey;
}