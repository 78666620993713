#report-container {
  padding: 12px;
}

.table-container {
  max-width: 100%;
  overflow-x: auto;
}

#report-container img {
  max-width: 100%;
}

#report-container table {
  border-collapse: collapse;
  max-width: 100%;
}

#report-container td,
#report-container th {
  border: 1px solid #ddd;
  padding: 8px;
}

#report-container th {
  background-color: #f2f2f2;
}

#report-container h1 {
  font-size: 2rem;
}

#report-container h2 {
  margin-top: 30px;
  font-size: 1.7rem;
}

#report-container h3 {
  margin-top: 20px;
  font-size: 1.3rem;
}

#report-container h4 {
  margin-top: 20px;
  font-size: 1.1rem;
}

#report-container h5 {
  margin-top: 20px;
  font-size: 1rem;
}

#report-container a {
  color: #000000;
}

#report-container ul {
  line-height: 1.6;
}