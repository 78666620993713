/* BOOTSTRAP OVERRIDES */

.card {
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1);
}
.card .card-header {
    font-weight: 500;
}
.card-header:first-child {
    border-radius: 0.35rem 0.35rem 0 0;
}
.card-header {
    padding: 1rem 1.35rem;
    margin-bottom: 0;
    background-color: rgba(33, 40, 50, 0.03);
    border-bottom: 1px solid rgba(33, 40, 50, 0.125);
}

.btn-close:focus {
    box-shadow: none;
}

.btn-close {
    font-size: 0.7rem;
}

.btn:focus-visible {
    box-shadow: none;
}

.btn {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .15s;
    --bs-btn-font-size: inherit;
}

.btn-dark, .btn-secondary, .btn-danger, .btn-outline, .btn-outline-dark {
    box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 3px 0 rgba(0,0,0,.1), 0 1px 2px -1px rgba(0,0,0,.1);
    font-weight: 400;
}

.btn-outline, .btn-outline:focus-visible, .btn-outline-dark, .btn-outline-danger, .btn-outline-secondary {
    box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(0,0,0,.05);
}

.btn-outline, .btn-outline:focus-visible {
    background-color: #ffffff;
    border: 1px solid hsl(240 5.9% 90%);
}

.btn-outline:hover, .btn-outline:active {
    background-color: hsl(240 4.8% 95.9%) !important;
    border: 1px solid #e5e7eb !important;
}

.btn-outline.show {
    background-color: #ffffff;
    border: 1px solid #e5e7eb !important;
}

/* MODALS */

.modal-backdrop.show {
    opacity: 0.8;
}

.modal-header {
    border-bottom: 0;
    padding-bottom: 0;
}

.modal-footer {
    border-top: 0;
    padding-top: 0;
}

.modal-content {
    padding: 10px;
}

/* FORMS */

.form-control, .form-select {
    box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(0,0,0,.05);
}

.form-check-input, .form-check-input:focus {
    border: 1px solid black !important;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1) !important;
}

.form-check-input:checked {
    background-color: var(--bs-body-color);
}

input[type=checkbox] {

}

/* STATUS MESSAGES */
.button-message-container {
    display: flex;
    align-items: center;
}

.error-success-message {
    width: auto;
    display: inline-block;
    margin-bottom: 0;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 0;
}

.error-success-message div {
    padding-top: 6px;
    padding-bottom: 6px;
    margin-bottom: 0;
}

/* CONTAINER */

@media (max-width: 768px) {
    .container-sm {
        max-width: 100%;
    }
}

@media (max-width: 992px) {
    .container-md {
        max-width: 100%;
    }
}

@media (max-width: 1200px) {
    .container-lg {
        max-width: 100%;
    }
}

/* BOOTSTRAP VARIANTS */

.nav-pills.dark {
    --bs-nav-pills-link-active-bg: #212529;
    --bs-nav-link-color: #212529;
    --bs-nav-link-hover-color: #4d5154;
}

.nav-pills.square {
    --bs-nav-pills-border-radius: 0px;
}

.hover-grey li:hover {
    background-color: #ebebeb;
}

.btn-unstyled {
    --bs-btn-padding-x: 0;
    --bs-btn-padding-y: 0;
    --bs-btn-hover-color: gray;
    border: none;
}

/* CORE ELEMENTS */

.flexbox-full-width {
    display: flex;
    width: 100%;
    align-items: center;
}

.flex-fill-horizontal {
    flex-grow: 1;
}

/* CSS TABLE STYLES */

.css-table {
    display: table;
}

.css-table-header {
    display: table-header-group;
    font-weight: bold;
    background-color: rgb(191, 191, 191);
}

.css-table-body {
    display: table-row-group;
}

.css-table-row {
    display: table-row;
}

.css-table-header div, .css-table-row div {
    display: table-cell;
    padding: 0 6px;
}

.css-table-header div {
    text-align: center;
    border: 1px solid rgb(255, 255, 255);
}

/* ADDITIONAL GLOBAL STYLES */

.shadow {
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1) !important;
}

.shadow-sm {
    box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(0,0,0,.05);
}

.lh-16 {
    line-height: 1.6 !important;
}

.lh-17 {
    line-height: 1.7 !important;
}

.small {
    font-size: .875em !important;
}

.smaller {
    font-size: 0.75rem;
}

.square {
    border-radius: 0;
}

/* SCROLLBAR ELEMENT */

.narrow-scrollbar::-webkit-scrollbar {
  width: 5px; /* For vertical scrollbar */
  height: 5px; /* For horizontal scrollbar */
}

.narrow-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.narrow-scrollbar::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 6px;
}

.narrow-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #888;
}

