
.dropdown-menu {
    --bs-dropdown-link-active-bg: var(--bs-body-color);
    --bs-dropdown-link-hover-bg: var(--bs-secondary-bg);
}

.navbar .notification-toggle::after {
    content: none;
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-left: none;
}

.navbar .dropdown-menu.notify-drop {
  min-width: 330px;
  background-color: #fff;
}

.navbar .dropdown-menu.notify-drop .notify-drop-title {
  border-bottom: 1px solid #e2e2e2;
  padding: 5px 15px 10px 15px;
}

.navbar .dropdown-menu.notify-drop .drop-content {
  max-height: 280px;
  overflow-y: scroll;
}

.navbar .dropdown-menu.notify-drop .drop-content::-webkit-scrollbar-track {
  background-color: #F5F5F5;
}

.navbar .dropdown-menu.notify-drop .drop-content::-webkit-scrollbar {
  width: 8px;
  background-color: #F5F5F5;
}

.navbar .dropdown-menu.notify-drop .drop-content::-webkit-scrollbar-thumb {
  background-color: #ccc;
}

.navbar .dropdown-menu.notify-drop .drop-content > li {
  border-bottom: 1px solid #e2e2e2;
  padding: 10px 15px;
}

.navbar .dropdown-menu.notify-drop .drop-content > li:nth-child(2n+0) {
  background-color: #fafafa;
}

.navbar .dropdown-menu.notify-drop .drop-content > li:after {
  content: "";
  clear: both;
  display: block;
}

.navbar .dropdown-menu.notify-drop .drop-content > li:hover {
  background-color: #fcfcfc;
}

.navbar .dropdown-menu.notify-drop .drop-content > li:last-child {
  border-bottom: none;
}

.navbar .dropdown-menu.notify-drop .allRead {
  margin-right: 7px;
}

.navbar .dropdown-menu.notify-drop .drop-content > li a {
  text-decoration: none;
}

.navbar .dropdown-menu.notify-drop .drop-content > li {
  font-weight: bold;
  font-size: 11px;
}

.navbar .dropdown-menu.notify-drop .drop-content > li hr {
  margin: 5px 0;
  width: 100%;
  border-color: #e2e2e2;
}

.navbar .dropdown-menu.notify-drop .drop-content > li p {
  font-size: 11px;
  color: #666;
  font-weight: normal;
  margin: 3px 0;
}

.navbar .dropdown-menu.notify-drop .drop-content > li div.time {
  font-size: 10px;
  font-weight: 600;
  padding: 0 3px;
  border: 1px solid #e2e2e2;
  position: relative;
  background-image: linear-gradient(#fff,#f2f2f2);
  display: inline-block;
  border-radius: 2px;
  color: #666;
}

.navbar .dropdown-menu.notify-drop .drop-content > li div.time:hover {
  background-image: linear-gradient(#fff,#fff);
}

.navbar .dropdown-menu.notify-drop .notify-drop-footer {
  border-top: 1px solid #e2e2e2;
  bottom: 0;
  position: relative;
  padding: 8px 15px 0;
}

.navbar .dropdown-menu.notify-drop .notify-drop-footer button {
  color: #777;
  text-decoration: none;
  font-size: small;
  padding: 0;
}

.navbar .dropdown-menu.notify-drop .notify-drop-footer button:hover {
  color: #333;
}

span.badge-notification {
    font-size: xx-small;
    padding: 3px 6px;
    font-weight: normal;
}

.nav-link:focus-visible {
  box-shadow: none;
}