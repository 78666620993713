ul.usage-list {
    margin-bottom: 0;
}

.usage-list li {
    align-items: center;
    padding-left: 10px;
    margin-bottom: 6px;
}

.usage-list li:last-child {
    margin-bottom: 0;
}

.usage-icon {
    margin-right: 10px;
}

.usage-table-container {
    overflow-x: auto;
}