.register-message {
    margin-bottom: 0;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 0;
}

.btn-register {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
}