.generic-table {
  width: 100%;
  border-collapse: collapse;
  /*border: 1px solid #ccc;*/
}

.generic-table th {
  font-weight: 600;
  background-color: rgba( 33,37,41 , 0.03);
  text-align: left;
}

.generic-table td {
  padding: 10px;
  border-top: 1px solid #ccc;
}

.generic-table td.loading {
  padding: 0;
}

.table-card {
  overflow: hidden;
}

.generic-table a {
  color: black;
  text-decoration: none;
  font-weight: 500;
}

.generic-table a:hover {
  text-decoration: underline;
  color: gray;
}

.table-nowrap .table td,
.table-nowrap .table th {
    white-space: nowrap;
}

.table>:not(caption)>*>* {
    padding: 0.75rem 1.25rem;
    border-bottom-width: 1px;
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 0;
}

.pagination-container .previous-page {
  margin-right: 15px;
}

.pagination-container .next-page {
  margin-left: 15px;
}

.pagination-container .page-info {
  text-align: center;
}

.pagination-container .page-link {
  color: black;
}

.pagination-container .page-link:hover {
  color: grey;
}
