.email-template-context .search-query-label {
    width: 100px;
}

.email-template-context .search-query {
    max-width: 450px;
    outline: none !important;
}

.email-template-context input[type=checkbox] {
    cursor: pointer;
}