@font-face {
  font-family: 'Geist';
  src: local('Geist'), url('./fonts/Geist-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}


@font-face {
  font-family: 'Geist';
  src: local('Geist'), url('./fonts/Geist-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: local('Geist'), url('./fonts/Geist-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: local('Geist'), url('./fonts/Geist-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Geist', -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background-color: #f7f7f7;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
}

a {
  color: #000000;
}

p {
  line-height: 1.6;
}

a:hover {
  color: #2b2f32;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Setting input font size to 16px to avoid auto-zoom on mobile */
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}
