.combobox .dropdown-toggle::after {
    margin-left: 10px;
    color: #bbb;
}

.combobox .form-control, .combobox .form-control:focus {
    border: 0 !important;
    outline: none !important;
}

.combobox .dropdown-button {
    width: 200px;
}

.combobox .dropdown-button>span {
    text-overflow: ellipsis;
}

.combobox .dropdown-item {
    border-radius: 6px;
}

.combobox .dropdown-item:hover, .combobox .dropdown-item:focus, .combobox .dropdown-item:active {
    background-color: hsl(240 4.8% 95.9%);
}

.combobox .dropdown-items {
    max-height: 200px;
    overflow-y: auto;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.combobox .dropdown-enter {
    animation: fadeIn 0.4s forwards;
}