button.btn-icon-tooltip {
    background-color: transparent;
    border: none; /* Optional, remove if you don't want borders */
    border-radius: 4px;
    min-width: 34px;
}

button.btn-icon-tooltip:hover {
    background-color: rgba(0.88, 0.88, 0.9, 0.1);
    color: black; /* Ensure the text color remains black on hover */
}

.icon-tooltip-text {
    font-size: small;
    position: fixed;
}
