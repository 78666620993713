.rectangle-shimmer {
   background: linear-gradient(-45deg, #eee 40%, #dcdcdc 50%, #eee 60%);
   background-size: 300%;
   background-position-x: 100%;
   animation: shimmer 1s infinite linear;
}

.card-shimmer {
   background: linear-gradient(-45deg, transparent 40%, #dcdcdc 50%, transparent 60%);
   background-size: 300%;
   background-position-x: 100%;
   animation: shimmer 1s infinite linear;
}

.card-shimmer .line-container {
   border-bottom: 1px solid #e5e5e5;
}

.card-shimmer .line-container:last-child {
   border-bottom: none;
}

.rectangles-right {
   height: 100%;
}

.square-left, .rectangle-right {
   background: #f1f1f1;
}

@keyframes shimmer {
   to {
      background-position-x: 0;
   }
}