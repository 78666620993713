.select-deal-box {
  position: relative;
  width: 100%;
}

.select-deal-box .search-form {
  position: relative;
  display: flex;
  align-items: center;
}

.select-deal-box .search-btn {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  padding: 0 12px;
  display: flex;
  align-items: center;
  color: #6c757d;
}

.select-deal-box .search-btn-cross {
  right: 40px;
}

.select-deal-box .search-box-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 100%;
  background: white;
  border: 1px solid #dee2e6;
  border-radius: 0.375rem;
  margin-top: 0.5rem;
  z-index: 1000;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
}

.select-deal-box .results-list {
  overflow-y: auto;
}

.select-deal-box .search-list {
  border-radius: 0.375rem;
}

.select-deal-box .list-group-item {
  border: none;
  padding: 0.75rem 1rem;
}

.select-deal-box .list-group-item.focused {
  background-color: #f8f9fa;
}

.select-deal-box .btn-search-result {
  width: 100%;
  padding: 0;
  border: none;
}

.select-deal-box .btn-search-result:hover .list-group-item {
  background-color: #f8f9fa;
} 